import React, { useEffect, Suspense } from "react";
import Routes from "./Routes";
//import Home from './pages/Home';
import { Navbar, Footer, Skills, Analytics } from "./components";

//import Hero from './components/Hero';
//import Start from './components/Start';

//import Newsletter from './components/Newsletter';
//import PaymentPlanCards from './components/PaymentPlanCards';

import "@fortawesome/fontawesome-free/css/all.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ScrollToTop from "react-scroll-to-top";
import CookieConsent, { Cookies } from "react-cookie-consent";

const App = () => {
  if (process.env.REACT_APP_ENV !== "development") {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
}

  useEffect(() => {
    AOS.init({
      once: true,
    });
  });
  return (
    <>
      <ScrollToTop color="green" smooth top="1000" style={{ bottom: "50%" }} />

      <HelmetProvider>
        {/* First section */}

        {Routes}
        <Footer />
        <CookieConsent
          enableDeclineButton
          location="bottom"
          buttonText="Am inteles"
          declineButtonText="Refuza"
          cookieName="myAwesomeCookieName2"
          style={{ background: "rgba(67, 69, 67, 0.5)" }}
          buttonStyle={{
            background: "rgb(228, 226, 215)",

            fontWeight: "bolder",
          }}
          expires={150}
        >
          Acest site web folosește cookie-uri pentru a îmbunătăți experiența
          utilizatorului. .
          <span>
            {" "}
            <a href="/page/politica-de-cookie-uri" style={{ color: "white" }}>
              Politica de cookie-uri
            </a>
          </span>
        </CookieConsent>
      </HelmetProvider>
    </>
  );
};

export default App;
