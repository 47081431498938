import { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import logo from "./starlogo.svg";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const { hash } = useLocation();
  const location = useLocation();
  console.log(location.pathname);
  const hisactive = (iHash) => hash === iHash;
  console.log(hisactive(hash));
  console.log(hash);
  console.log(hisactive("#aboutus"));
  const lActive = (iLink) => location.pathname === iLink;
  console.log(lActive("/projects"));
  const navClickHandler = () => {
    setNav(!nav);
  };
  const [top, setTop] = useState(true);

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  /*  let activestyle = {
    textDecoration: "underline",
  }; */

  let activeClassName = "underline";
  const navigate = useNavigate();
  return (
    <div
      className={`fixed left-0 top-0 z-30 w-full px-4 flex justify-between items-center h-24 mx-auto bg-transparent text-green-500 ${
        !top && "bg-gray backdrop-blur-sm shadow-lg"
      }`}
    >
      {/* Navbar title - desktop */}
      <a
        className="text-gray-800 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
        href="/#"
      >
        <img
          src={logo}
          width="40"
          height="40"
          align="left"
          alt="Star Soft Design"
        />
        {top && (
          <span className="w-full text-2xl font-bold text-[#00df9a] uppercase">
            &nbsp;&nbsp;Star Soft Design
          </span>
        )}
      </a>

      <ul className="hidden md:flex space-x-8 font-bold">
        {/* Navbar list - desktop */}
        <li className="cursor-pointer text-green-600 hover:bg-blue-700 hover:rounded-full hover:text-white">
          <a className="nav-link" href="/#">
            Home
          </a>
          {/*
			<NavLink className='nav-link'
            to='/#'>
            Home
          </NavLink> 
		  		{/*    <NavHashLink
				                className='nav-link'
                                to='/#home'
                                smooth={true}
                                spy='true'
                                duration={2000}
								style={
          hisactive('#home')
            ? {
                color: 'red'
              }
            : {}
        }
        activestyle={{
          fontWeight: 'bold',
          color: 'red'
        }}>

                                        Home

            </NavHashLink>*/}
        </li>
        <li className="cursor-pointer text-green-600 hover:bg-blue-700 hover:rounded-full hover:text-white">
          <NavHashLink
            className="nav-link"
            to="/#aboutus"
            smooth={true}
            spy="true"
            duration={2000}
            style={
              hisactive("#aboutus")
                ? {
                    color: "red",
                  }
                : {}
            }
            activestyle={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            About
          </NavHashLink>
        </li>
        <li className="cursor-pointer text-green-600 hover:bg-blue-700 hover:rounded-full hover:text-white">
          <NavHashLink
            className="nav-link"
            to="/#services"
            smooth={true}
            spy="true"
            duration={2000}
            style={
              hisactive("#services")
                ? {
                    color: "red",
                  }
                : {}
            }
            activestyle={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            Services
          </NavHashLink>
        </li>
        <li className="cursor-pointer text-green-600 hover:bg-blue-700 hover:rounded-full hover:text-white">
          <NavLink
            className="nav-link"
            to="/projects"
            style={
              lActive("/projects")
                ? {
                    color: "red",
                  }
                : {}
            }
          >
            Projects
          </NavLink>
        </li>
        <li className="cursor-pointer text-green-600 hover:bg-blue-700 hover:rounded-full hover:text-white">
          <NavHashLink
            className="nav-link"
            to="/#products"
            smooth={true}
            spy="true"
            duration={2000}
            style={
              hisactive("#products")
                ? {
                    color: "red",
                  }
                : {}
            }
            activestyle={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            Products
          </NavHashLink>
        </li>
        <li className="cursor-pointer text-green-600 hover:bg-blue-700 hover:rounded-full hover:text-white">
          <a
            href="https://support.starsoftdesign.ro/"
            target="_blank"
            className="nav-link"
          >
            Support
          </a>
        </li>
        <li className="cursor-pointer text-green-600 hover:bg-blue-700 hover:rounded-full hover:text-white hover:font-bold">
          <NavHashLink
            className="nav-link"
            to="/#contact"
            smooth={true}
            spy="true"
            duration={2000}
            style={
              hisactive("#contact")
                ? {
                    color: "red",
                  }
                : {}
            }
            activestyle={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            Contact
          </NavHashLink>
        </li>
      </ul>
      <div onClick={navClickHandler} className="block md:hidden">
        {/* Hamburger menu */}
        {nav ? (
          <AiOutlineClose size={20} className="text-green-500 font-bold" />
        ) : (
          <AiOutlineMenu size={20} />
        )}
      </div>
      <div
        className={
          nav
            ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 px-4 bg-[#000300] ease-in-out duration-500"
            : "fixed left-[-100%]"
        }
      >
        {/* Navbar title - mobile */}
        <a
          className="text-gray-800 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
          href="/#"
        >
          <span className="w-full m-4 text-xl font-bold text-[#00df9a] uppercase">
            Star Soft Design
          </span>
        </a>
        <ul className="flex flex-col p-2 uppercase">
          {/* Navbar list - mobile */}
          <li className="border-b p-2 border-gray-600">
            {" "}
            <a className="nav-link hover:bg-blue-700" href="/#">
              Home
            </a>
          </li>
          <li className="border-b p-2 border-gray-600 ">
            <a href="/#aboutus" className="nav-link hover:bg-blue-700">
              About
            </a>
          </li>
          <li className="border-b p-2 border-gray-600">
            <a href="/#services" className="nav-link">
              Services
            </a>
          </li>
          <li className="border-b p-2 border-gray-600">
            <NavLink className="nav-link" to="/projects">
              Projects
            </NavLink>
          </li>
          <li className="border-b p-2 border-gray-600">
            <a href="/#products" className="nav-link">
              Product
            </a>
            s
          </li>
          <li className="border-b p-2 border-gray-600">
            <a
              href="https://support.starsoftdesign.ro/"
              target="_blank"
              className="nav-link"
            >
              Support
            </a>
          </li>
          <li className="p-2">
            <a href="/#contact" className="nav-link">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
