import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import ManageCareTeam from './Components/ManageCareTeam';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Pages from './pages/Pages';
//import Resources from './Components/Resources';
//import SignUp from './Components/SignUp';
//import Login from './Components/Login';
//import Logout from './Components/Logout';
//import PrivateRoutes from './Components/PrivateRoutes';
import NotFoundPage from "./pages/404Page";
import ModalProjectCard from './components/Projects/ModalProjectCard';


export default (
    <Routes onUpdate={() => window.scrollTo(0, 0)}>
        {/* <PrivateRoute exact path='/' component={Home} /> */}
        {/* <PrivateRoutes path='/manage-care-team' element={<ManageCareTeam />} /> */}
        <Route path='/' element={<Home />} />
	    <Route path='/home' element={<Home />} /> 
        <Route path='/projects' element={<Projects />} />
        <Route path='/project/:id' element={<ModalProjectCard />} />
		<Route path="/page/:id" element={<Pages/>} />
		<Route path="*" element={<NotFoundPage />} />
    </Routes>
);